import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'

const items = [
  {
    link: '/lawn-garden/lawn-tractors/',
    imageId: 'x500Image',
    name: 'Lawn Tractors',
    alt: 'John Deere Lawn Tractors',
  },
  {
    link: '/lawn-garden/residential-zero-turns/',
    imageId: 'z300Image',
    name: 'Residential Zero Turns',
    alt: 'John Deere Residential Zero Turns',
  },
  {
    link: '/lawn-garden/commercial-mowers/',
    imageId: 'z900Image',
    name: 'Commercial Mowers',
    alt: 'John Deere Commercial Mowers',
  },
]

const MowersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Mowers | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson offers a wide variety of John Deere mowers, perfect for all applications. Whether you’re mowing your lawn, a large property, or several properties.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Mowers',
                'item': 'https://www.hutsoninc.com/lawn-garden/mowers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Mowers' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Mowers'>
          <p>
            There's a John Deere mower for almost any shape, size, or type of lawn. Whether you own
            a half-acre or professionally maintain twenty different lawns, you can find the right
            fit at Hutson. Our lineup includes{' '}
            <Link to='/lawn-garden/lawn-tractors/'>riding lawn mowers and tractors</Link>,{' '}
            <Link to='/lawn-garden/residential-zero-turns/'>zero-turn mowers</Link>, and{' '}
            <Link to='/lawn-garden/commercial-mowers/z900-series/'>commercial zero-turns</Link>,{' '}
            <Link to='/lawn-garden/commercial-mowers/walk-behind-mowers/'>walk-behinds</Link>, and{' '}
            <Link to='/lawn-garden/commercial-mowers/quiktrak-series/'>stand-ons</Link>. Not sure
            which one is right for you? Take a look at our{' '}
            <Link to='/blog/riding-mower-vs-zero-turn/'>
              blog post comparing riding mowers and zero-turns
            </Link>
            .
          </p>
        </Copy>

        <Promos data={promos} type='Mower' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(
      filter: {
        categoryList: {
          elemMatch: {
            category: { regex: "/residential-zero-turns|lawn-tractors|commercial-mowers/" }
            type: { eq: "john-deere" }
          }
        }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/residential-mowers-header.jpg" }) {
      ...FullWidthImage
    }
    x500Image: file(relativePath: { eq: "lawn-and-garden/x500-series.jpg" }) {
      ...FloatingGridImage
    }
    z300Image: file(relativePath: { eq: "lawn-and-garden/z300-series.jpg" }) {
      ...FloatingGridImage
    }
    z900Image: file(relativePath: { eq: "lawn-and-garden/z900-series.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default MowersPage
